import React from 'react';
import styled from 'styled-components';

import Narrow from './Narrow';
import SmallText from './SmallText';

const HL = styled.h2`
  font-family: ffvCorporateFat;
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-align: center;
  color: ${props => (props.theme.black ? 'white' : 'black')};
`;

// const Content = styled.div`
//   font-size: 1rem;
//   font-family: ffvGaramond;
//   font-weight: 100;
//   text-align: center;
//   color: ${props => (props.theme.black ? 'white' : 'black')};
// `;

export default props => (
  <>
    <Narrow>
      <HL>Credits</HL>
      <SmallText dangerouslySetInnerHTML={{ __html: props.content }} />
    </Narrow>
  </>
);
