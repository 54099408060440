import styled from 'styled-components';

export default styled.div`
  position: relative;
  margin-top: 2rem;
  width: 100%;
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  order: 3;
  background: pink;
`;
