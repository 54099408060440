import { Element } from 'react-scroll';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';

import AspectRatioContainer from './AspectRatioContainer';
import Credits from './Credits';
import InternalLink from './InternalLink';
import InterviewBlocks from './InterviewBlocks';
import Narrow from './Narrow';
import ProjectStage from './ProjectStage';
import SmallText from './SmallText';
import Subheading from './Subheading';
import TextBlob from './Textblob';
import Video from './Video';
import VideoWithViewport from './VideoWithViewport';
import WPImage from './WPImage';

const renderTextBlob = block => (
  <Grid item xs={12}>
    <Narrow>
      <TextBlob>{block.text_blob}</TextBlob>
    </Narrow>
  </Grid>
);
const renderFullWidthImages = block =>
  block.full_width_images.map((e, i) => (
    <Grid key={e.url} item xs={12}>
      <AspectRatioContainer aspectRatio={1.78}>
        <WPImage cover src={e.url} srcSet={e.sizes} sizes={'100vw'} />
      </AspectRatioContainer>
    </Grid>
  ));
const renderTwoImages = block => (
  <>
    <Grid item xs={12} md={6}>
      <AspectRatioContainer aspectRatio={1.5}>
        <WPImage
          cover
          src={block.two_images[0].url}
          srcSet={block.two_images[0].sizes}
          sizes={'(max-width: 960px) 100vw, 50vw'}
        />
      </AspectRatioContainer>
    </Grid>
    <Grid item xs={12} md={6}>
      <AspectRatioContainer aspectRatio={1.5}>
        <WPImage
          cover
          src={block.two_images[1].url}
          srcSet={block.two_images[1].sizes}
          sizes={'(max-width: 960px) 100vw, 50vw'}
        />
      </AspectRatioContainer>
    </Grid>
  </>
);

const renderThreeImages = block => (
  <>
    <Grid item xs={12} md={4}>
      <AspectRatioContainer aspectRatio={1.5}>
        <WPImage
          cover
          src={block.three_images[0].url}
          srcSet={block.three_images[0].sizes}
          sizes={'(max-width: 960px) 100vw, 33vw'}
        />
      </AspectRatioContainer>
    </Grid>
    <Grid item xs={12} md={4}>
      <AspectRatioContainer aspectRatio={1.5}>
        <WPImage
          cover
          src={block.three_images[1].url}
          srcSet={block.three_images[1].sizes}
          sizes={'(max-width: 960px) 100vw, 33vw'}
        />
      </AspectRatioContainer>
    </Grid>
    <Grid item xs={12} md={4}>
      <AspectRatioContainer aspectRatio={1.5}>
        <WPImage
          cover
          src={block.three_images[2].url}
          srcSet={block.three_images[2].sizes}
          sizes={'(max-width: 960px) 100vw, 33vw'}
        />
      </AspectRatioContainer>
    </Grid>
  </>
);

const renderCredits = block => (
  <Grid item xs={12}>
    <Credits content={block.credits} />
  </Grid>
);
const renderSubHeadline = block => (
  <Grid item xs={12}>
    <Subheading>{block.sub_heading}</Subheading>
  </Grid>
);
const renderContentVideo = block => {
  const VideoElement = block.content_video.show_controls_on_hover
    ? VideoWithViewport
    : Video;

  return (
    <Grid item xs={12}>
      <VideoElement
        hd={block.content_video.hd}
        sd={block.content_video.sd}
        poster={
          block.content_video.poster_image
            ? block.content_video.poster_image.url
            : ''
        }
        loop
        autoPlay={!block.content_video.show_controls_on_hover}
        overlay={block.content_video.fade_over_black}
        controls={block.content_video.show_controls_on_hover}
      />
    </Grid>
  );
};

const renderTwoContentVideos = block => (
  <>
    <Grid item xs={12} md={6}>
      <Video
        hd={block.content_video_1.hd}
        sd={block.content_video_1.sd}
        poster={
          block.content_video_1.poster_image
            ? block.content_video_1.poster_image.url
            : ''
        }
        loop
        autoPlay={!block.content_video_1.show_controls_on_hover}
        overlay={block.content_video_1.fade_over_black}
        controls={block.content_video_1.show_controls_on_hover}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <Video
        hd={block.content_video_2.hd}
        sd={block.content_video_2.sd}
        poster={
          block.content_video_2.poster_image
            ? block.content_video_2.poster_image.url
            : ''
        }
        loop
        autoPlay={!block.content_video_2.show_controls_on_hover}
        overlay={block.content_video_2.fade_over_black}
        controls={block.content_video_2.show_controls_on_hover}
      />
    </Grid>
  </>
);

const renderReelStage = block => (
  <>
    <Grid item xs={12}>
      <Subheading style={{ marginBottom: '-2rem' }}>Our latest Showreel</Subheading>
    </Grid>
    <Grid item xs={12}>
      <ProjectStage
        video
        background={block.background_image.url}
        sd={block.video.sd}
        hd={block.video.hd}
        poster={block.video.poster_image ? block.video.poster_image.url : ''}
        overlay={block.video.fade_over_black}
        controls={block.video.show_controls_on_hover}
        autoPlay
      />
    </Grid>
  </>
);
const renderAboutBlob = block => (
  <>
    <Grid item xs={12}>
      <Narrow>
        <TextBlob
          style={{
            marginTop: '6rem',
            fontSize: '1.7rem',
            lineHeight: '1.4',
            paddingBottom: '1.5rem',
          }}
        >
          {block.about_text}
        </TextBlob>
        <Link to="/about">
          <InternalLink style={{ fontSize: '1.2rem', marginBottom: '6rem' }}>
            {block.link_text}
          </InternalLink>
        </Link>
      </Narrow>
    </Grid>
  </>
);

const renderInterviewBlock = block => (
  <Grid item xs={12}>
    <Narrow>
      <InterviewBlocks question={block.question} answer={block.answer} />
    </Narrow>
  </Grid>
);

const renderSmallText = block =>
  block.small_text && (
    <Grid item xs={12}>
      <Narrow>
        <SmallText dangerouslySetInnerHTML={{ __html: block.small_text }} />
      </Narrow>
    </Grid>
  );

const renderInterviewJump = block => (
  <Element name="team">
    <div style={{ height: '1px' }} />;
  </Element>
);

export default ({ content }) => {
  if (!content) return null;
  const contentArray = content.map(block => {
    switch (block.acf_fc_layout) {
      case 'text_blob':
        return renderTextBlob(block);
      case 'about_blob':
        return renderAboutBlob(block);
      case 'full_width_images':
        return renderFullWidthImages(block);
      case 'two_images':
        return renderTwoImages(block);
      case 'three_images':
        return renderThreeImages(block);
      case 'credits':
        return renderCredits(block);
      case 'content_video':
        return renderContentVideo(block);
      case 'two_videos':
        return renderTwoContentVideos(block);
      case 'sub_headline':
        return renderSubHeadline(block);
      case 'reel_stage':
        return renderReelStage(block);
      case 'interview_block':
        return renderInterviewBlock(block);
      case 'small_text':
        return renderSmallText(block);
      case 'interview_jump_target':
        return renderInterviewJump(block);
      default:
        return null;
    }
  });
  return (
    <Grid container justify="center" spacing={24}>
      {contentArray.map((e, i) => (
        <React.Fragment key={content.length + i}>{e}</React.Fragment>
      ))}
    </Grid>
  );
};
