import React from 'react';
import styled from 'styled-components';

import Textblob from './Textblob';

const Answer = styled(Textblob)`
  margin-top: 1.5rem;
  margin-bottom: 0;
`;

const Question = styled.p`
  color: ${({ theme }) => (theme.black ? 'white' : 'black')};
  font-size: 1.5rem;
  line-height: 1.3;
  font-family: ffvGaramond;
  font-weight: 100;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
`;

const InterviewBlock = ({ question, children }) => (
  <>
    <Question>{question}</Question>
    <Answer>{children}</Answer>
  </>
);

export default ({ question, answer, width }) => (
  <InterviewBlock question={question}>{answer}</InterviewBlock>
);
