import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import StageContainer from './StageContainer';
import VideoWithViewport from './VideoWithViewport';
import originalWPImage from './WPImage';

const VideoContainer = styled.div`
  width: 80%;
  height: auto;
  margin: 2rem auto;
  z-index: 80;
  @media all and (min-aspect-ratio: 5/3) {
    width: 70%;
  }
`;

const Background = styled(originalWPImage)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 0;
  width: 80%;
  padding-top: 45%;
  margin: 2rem auto;
  z-index: 80;
  @media all and (min-aspect-ratio: 5/3) {
    width: 70%;
    padding-top: 39.37%;
  }
`;

const WPImage = styled(originalWPImage)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export default class ProjectStage extends React.Component {
  render() {
    return (
      <>
        <StageContainer>
          <Background
            cover
            src={this.props.background}
            srcSet={this.props.bgSrcSet}
            sizes={'100vw'}
          />
          {this.props.video && (
            <VideoContainer>
              <VideoWithViewport
                sd={this.props.sd}
                hd={this.props.hd}
                poster={this.props.poster}
                fullscreen
                autoPlay
                loop
                overlay={this.props.overlay}
                controls={this.props.controls}
              />
            </VideoContainer>
          )}
          {this.props.image && (
            <ImageContainer>
              <WPImage
                cover
                src={this.props.src || ''}
                srcSet={this.props.srcSet}
                sizes={'80vw'}
                empty={!this.props.src}
              />
            </ImageContainer>
          )}
        </StageContainer>
      </>
    );
  }
}

ProjectStage.propTypes = {
  video: PropTypes.bool,
  image: PropTypes.bool,
  background: PropTypes.string.isRequired,
  src: PropTypes.string,
  sd: PropTypes.string,
  hd: PropTypes.string,
  poster: PropTypes.string,
};
